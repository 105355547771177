import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import logo from './flaglogo.png';
import { analyzeActorsAndBehavior } from './analysis';
import { transcribeSpeech } from './voiceTranscription';
import { SideDrawer, MainContent, QueryDialog } from './ui';
import { storeData } from './dataStorage';
import { generateNewUrl, resetUrl, handlePageLoad } from './url';
import LoadingPage from './LoadingPage';

// Add version information
const APP_VERSION = '1.0.0';  // You can update this as needed

function App() {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');
  const [queries, setQueries] = useState(() => {
    const savedQueries = localStorage.getItem('queryHistory');
    return savedQueries ? JSON.parse(savedQueries) : [];
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fadeOutLoading, setFadeOutLoading] = useState(false);
  const [adviceLoading, setAdviceLoading] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [imageUris, setImageUris] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [parsedResults, setParsedResults] = useState([]);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const theme = useTheme();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    localStorage.setItem('queryHistory', JSON.stringify(queries));
  }, [queries]);

  const categorizeQueries = (queries) => {
    const now = new Date();
    const today = now.setHours(0, 0, 0, 0);
    const yesterday = new Date(today - 86400000);
    const sevenDaysAgo = new Date(today - 86400000 * 7);
    const thirtyDaysAgo = new Date(today - 86400000 * 30);

    const categories = {
      Today: [],
      Yesterday: [],
      Last7Days: [],
      Last30Days: [],
      Older: [],
    };

    queries.forEach(query => {
      const queryDate = new Date(query.timestamp).setHours(0, 0, 0, 0);
      if (queryDate === today) {
        categories.Today.push(query);
      } else if (queryDate === yesterday.getTime()) {
        categories.Yesterday.push(query);
      } else if (queryDate > sevenDaysAgo.getTime()) {
        categories.Last7Days.push(query);
      } else if (queryDate > thirtyDaysAgo.getTime()) {
        categories.Last30Days.push(query);
      } else {
        categories.Older.push(query);
      }
    });

    return categories;
  };

  useEffect(() => {
    const initializeApp = async () => {
      await handlePageLoad(setQuery, setImageUris, setParsedResults);
      // Add a 3-second delay before starting the fade-out
      setTimeout(() => {
        setFadeOutLoading(true);
        // Add a 0.5-second delay for the fade-out animation before setting initialLoading to false
        setTimeout(() => {
          setInitialLoading(false);
        }, 230);
      }, 1300);
    };
    initializeApp();
  }, []);

  const handleQueryClick = useCallback((item) => {
    setSelectedQuery(item);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedQuery(null);
  }, []);

  const deleteQuery = useCallback((indexToDelete) => {
    setQueries(prevQueries => prevQueries.filter((_, index) => index !== indexToDelete));
  }, []);

  const truncateText = useCallback((text, maxLength) => text.length > maxLength ? text.substring(0, maxLength) + '...' : text, []);

  const processInput = useCallback(async (imageUris, textInput) => {
    console.time('processInput');
    setAdviceLoading(true);
    setResults([]);
    try {
      const input = { text: textInput || '', imageUris: imageUris || [] };
      console.log("Processing input:", input);
      const analysisResults = await analyzeActorsAndBehavior(input);
      if (analysisResults.length) {
        const uniqueKey = await storeData(input, analysisResults);
        setQueries(prevQueries => {
          const newQueries = [...prevQueries, { query: input.text, imageUrl: input.imageUris, timestamp: new Date().toISOString(), results: analysisResults }];
          localStorage.setItem('queryHistory', JSON.stringify(newQueries));
          return newQueries;
        });
        setNewUrl(generateNewUrl(uniqueKey));
        setResults(analysisResults);
      } else {
        console.error('No analysis results from the input.');
      }
    } catch (error) {
      console.error("Error processing input:", error);
    } finally {
      setAdviceLoading(false);
      console.timeEnd('processInput');
    }
  }, []);

  const handleImageUpload = useCallback((uploadedImageUrls) => {
    setImageUris(uploadedImageUrls);
  }, []);

  const handleUserInput = useCallback(async () => {
    await processInput(imageUris, query);
  }, [imageUris, query, processInput]);

  const clearAllContent = useCallback(() => {
    setResults([]);
    setQuery('');
    setImageUris([]);
    setParsedResults([]);
    resetUrl();
  }, []);

  const toggleDrawer = useCallback((open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  }, []);

  const toggleRecording = useCallback(async () => {
    if (isListening) {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
        setIsListening(false);
        await new Promise(resolve => {
          mediaRecorderRef.current.onstop = resolve;
        });
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const transcription = await transcribeSpeech(audioBlob);
        setQuery(prevQuery => prevQuery + (prevQuery ? ' ' : '') + transcription);
        audioChunksRef.current = [];
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.start();
        setIsListening(true);
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    }
  }, [isListening]);

  return (
    <>
      {initialLoading && <LoadingPage fadeOut={fadeOutLoading} />}
      {!initialLoading && (
        <div>
          <SideDrawer
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            categorizeQueries={categorizeQueries}
            queries={queries}
            handleQueryClick={handleQueryClick}
            truncateText={truncateText}
            deleteQuery={deleteQuery}
            setIsFeedbackOpen={setIsFeedbackOpen}
          />
          <MainContent
            theme={theme}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            query={query}
            setQuery={setQuery}
            handleImageUpload={handleImageUpload}
            imageUris={imageUris}
            handleUserInput={handleUserInput}
            resetUrl={clearAllContent}
            setResults={setResults}
            results={results}
            parsedResults={parsedResults}
            loading={adviceLoading}
            newUrl={newUrl}
            isListening={isListening}
            toggleListening={toggleRecording}
            logo={logo}
            appVersion={APP_VERSION}
            isFeedbackOpen={isFeedbackOpen}
            setIsFeedbackOpen={setIsFeedbackOpen}
          />
          <QueryDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            selectedQuery={selectedQuery}
          />
        </div>
      )}
    </>
  );
}

export default App;